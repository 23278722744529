import React, { Component } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import UploadContainer from '../containers/UploadContainer';

export default class EditProduct extends Component {

  constructor(props) {
    super(props)

    // Setting up functions
    this.onChangeProductCat = this.onChangeProductCat.bind(this);
    this.onChangeProductTitle = this.onChangeProductTitle.bind(this);
    //this.onChangeProductImg = this.onChangeProductImg.bind(this);
    this.onChangeProductDesc = this.onChangeProductDesc.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    // Setting up state
    this.state = {
      cat: '',
      title: '',
      //img: '',
      desc: ''
    }
  }

onChangeProductCat(e) {
    this.setState({ cat: e.target.value, img: document.getElementById('foto').value })
  }

  onChangeProductTitle(e) {
    this.setState({ title: e.target.value, img: document.getElementById('foto').value })
  }


  onChangeProductDesc(e) {
    this.setState({ desc: e.target.value, img: document.getElementById('foto').value })
  }

  componentDidMount() {
    axios.get(process.nv.REACT_APP_HOST+'/products/edit-product/' + this.props.match.params.id)
      .then(res => {
        this.setState({
          cat: res.data.cat,
          title: res.data.title,
          img: res.data.img,
          desc: res.data.desc
        });
      })
      .catch((error) => {
        console.log(error);
      })
  }

  

  onSubmit(e) {
    e.preventDefault()

   const productObject = {
      cat: this.state.cat,
      title: this.state.title,
      img: document.getElementById('foto').value,
      desc: this.state.desc
    };

    axios.put(process.env.REACT_APP_HOST+'/products/update-product/' + this.props.match.params.id, productObject)
      .then((res) => {
        console.log(res.data)
        console.log('Product successfully updated')
    // Redirect to Product List 
    this.props.history.push('/admin')
      }).catch((error) => {
        console.log(error)
      })    
  }


  render() {
const im = '/uploads/'+this.state.img;  

    return (<div className="form-wrapper">
      <Form onSubmit={this.onSubmit} style={{marginTop:"-50px"}}>

<Form.Group controlId="Cat">
        <label>
          Categoría: {' '}
          <select value={this.state.cat} onChange={this.onChangeProductCat}>
            <option value="Aromas">Aromas</option>
            <option value="Salud">Salud</option>
            <option value="Belleza">Belleza</option>
          </select>
        </label>          
        </Form.Group>

        <Form.Group controlId="Title">
          <Form.Label>Título</Form.Label>
          <Form.Control type="text" value={this.state.title} onChange={this.onChangeProductTitle} />
        </Form.Group>
        
        Imagen<br/><input type="hidden" id="foto" value={this.state.img} />
        <div id="upl" style={{float: "left", width:"100%", height:"120px"}}>       
               <img src={im} width="100" height="100" style={{marginLeft:"30px"}} />                     
               <UploadContainer />                
          </div>
          
   
        <Form.Group controlId="Desc">
            <label htmlFor="exampleFormControlTextarea1">Descripción</label>
            <textarea className="form-control" value={this.state.desc} onChange={this.onChangeProductDesc} rows="5"/>          
        </Form.Group>
        

        <Button variant="danger" size="lg" block="block" type="submit">
          Guardar cambios
        </Button>

      </Form>
    </div>);
  }
}