import React, { Component } from "react";
import axios from 'axios';
export default class ProductView extends Component {

  constructor(props) {
    super(props)
    // Setting up state
    this.state = {
      cat: '',
      title: '',
      img: '',
      desc: ''
    }
  }

  componentDidMount() {
    axios.get(process.env.REACT_APP_HOST + '/products/product-view/' + this.props.match.params.id)
      .then(res => {
        this.setState({
          cat: res.data.cat,
          title: res.data.title,
          img: res.data.img,
          desc: res.data.desc
        });
      })
      .catch((error) => {
        console.log(error);
      })
  }

  render() {
    const im = '/uploads/' + this.state.img;

    const urtit = encodeURIComponent(this.state.title);
    const wappmsg = 'https://wa.me/5493425480906?text=Hola!%20consulto%20sobre%20el%20producto%20'+urtit+':%20%0A_%0A';

    return (<div className="form-wrapper">

      <div style={{ float: "auto", width: "100%", height: "auto", paddingBottom:"20px",marginTop:"30px", textAlign: "center", color: "#037248", fontSize: "22px" }}>{this.state.title}</div>

      <div style={{ float: "left", width: "100%", height: "auto" }}><img src={im} style={{ float: "left", marginLeft:"-20px", width: "calc(100% + 40px)", height: "auto" }} /></div>
      
      <div style={{ float: "left", width: "100%", height: "auto", paddingTop: "10px", color: "#333" }}><a href={wappmsg}><img src="/imgs/whatsapp.png" width="50" style={{float:"left",marginLeft:"10px",maxWidth:"100%"}} /></a></div>

      <div style={{ float: "left", width: "100%", height: "auto", paddingTop: "10px", color: "#333" }}>{this.state.desc}</div>

      <div style={{ float: "left", width: "100%", height: "100px" }}></div>

    </div>

    );
  }
}