import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Button from 'react-bootstrap/Button';

export default class AdminProductTableRow extends Component {

    constructor(props) {
        super(props);
        this.deleteProduct = this.deleteProduct.bind(this);
    }




    deleteProduct() {

        axios.get(process.env.REACT_APP_HOST+'/products/delete-img/' + this.props.obj.img).then((res) => {
            console.log('iamge successfully deleted!')
        }).catch((error) => {
            console.log(error)
        })


        axios.delete(process.env.REACT_APP_HOST+'/products/delete-product/' + this.props.obj._id).then((res) => {
            window.location.reload();
            console.log('Product successfully deleted!')
        }).catch((error) => {
            console.log(error)
        })

    }




    render() { 
const im = '/uploads/'+this.props.obj.img;  
        return (

<div style={{marginTop:"-40px",float:"left",width:"100%",maxWidth:"300px",height:"350px",overflow:"hidden",marginBottom:"50px",marginLeft:"20px",marginRight:"20px"}}>    

<div style={{float: "left", width:"100%", height:"30px", textAlign:"center",color:"#037248"}}>{this.props.obj.title}</div>
<div style={{float: "left", width:"100%", height:"250px",overflow:"hidden"}}><img src={im} style={{float: "left", width:"100%", height:"auto"}} /></div>

<div style={{float: "left", width:"100%", height:"auto", backgroundColor:"#eee", padding:"10px"}}>               
                    <Link className="edit-link" to={"/admin/edit-product/" + this.props.obj._id}>
                        Editar
                    </Link>
                    <Button className="delete" onClick={() => {if(window.confirm('Eliminar producto?')){this.deleteProduct()};}} size="sm" variant="danger">X</Button>
</div>




</div>

        );
    }
}