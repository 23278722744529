import React, { Component } from "react";
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import AdminProductTableRowCat from './AdminProductTableRowCat';
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.css';


export default class AdminProductList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      products: []
    };
  }

  componentDidMount() {
    axios.get(process.env.REACT_APP_HOST+'/products/cat/'+this.props.match.params.cat)
      .then(res => {
        this.setState({
          products: res.data
        });
      })
      .catch((error) => {
        console.log(error);
      })
  }

  DataTable() {
    return this.state.products.map((res, i) => {
      return <AdminProductTableRowCat obj={res} key={i} />;
    });
  }


  render() { 



    window.onload = function () {
      $(document).ready(function () {
        $("#image-list").sortable({
          update: function (event, ui) {
            document.getElementById('submitbutton').style.display = 'block';
          }
        });

        $('#submit').click(function (e) {
          e.preventDefault();
          document.getElementById('submitbutton').innerHTML = 'Guardando...';
          var lico = $('ul#image-list li').length;
          var orArr = [];

          $('#image-list li').each(function (index) {
            var id = $(this).attr('id');
            var li = lico - index;
            orArr.push('[\"' + id + '\", \"' + li + '\"]');
          });

          axios.post(process.env.REACT_APP_HOST + '/products/order/' + orArr).then((res) => {
            window.location.reload();
          }).catch((error) => {
            console.log(error)
          })

        });
      });

    }

    return (
        
            
        

<ul id="image-list" class="image-list" className="table-wrapper" >

        <div id="submitbutton" style={{display:"none",position:"fixed",top:"0px",width:"50%",height:"50px",textAlign:"right",zIndex:"9999"}}> 
            <input type='button' class="btn-submit" value='Guardar orden!' id='submit' style={{backgroundColor:"#28a745",color:"#fff",border:"1px solid lightblue",padding:"0px 10px 5px 10px",borderRadius:"0px 0px 10px 10px"}} />
        </div>


          {this.DataTable()}
      </ul>);
  }
}