import React, { Component } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import UploadContainer from '../containers/UploadContainer';

export default class CreateProduct extends Component {

  constructor(props) {
    super(props)

    // Setting up functions
    this.onChangeProductCat = this.onChangeProductCat.bind(this);
    this.onChangeProductTitle = this.onChangeProductTitle.bind(this);
    //this.onChangeProductImg = this.onChangeProductImg.bind(this);
    this.onChangeProductDesc = this.onChangeProductDesc.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    // Setting up state
    this.state = {
      cat: 'Aromas',
      title: '',
      //img: '',
      desc: ''
    }
  }

  onChangeProductCat(e) {
    this.setState({ cat: e.target.value })
  }

  onChangeProductTitle(e) {
    this.setState({ title: e.target.value })
  }

  onChangeProductDesc(e) {
    this.setState({ desc: e.target.value })
  }

  onSubmit(e) {

    e.preventDefault()

    const productObject = {
      cat: this.state.cat,
      title: this.state.title,
      img: document.getElementById('foto').value,
      desc: this.state.desc
    };
    axios.post(process.env.REACT_APP_HOST+'/products/create-product', productObject)
      .then(res => this.props.history.push('/admin'));
    
    this.setState({ cat: '', title: '', img: '', desc: '' })
  }


  render() {
    return (
<div className="form-wrapper" style={{marginTop:"-50px"}}>
    

      <Form onSubmit={this.onSubmit}>


        <Form.Group controlId="Cat">
        <label>
          Categoría: {' '}
          <select value={this.state.cat} onChange={this.onChangeProductCat}>
            <option value="Aromas">Aromas</option>
            <option value="Belleza">Belleza</option>
            <option value="Salud">Salud</option>
          </select>
        </label>          
        </Form.Group>

        <Form.Group controlId="Title">
          <Form.Label>Título</Form.Label>
          <Form.Control type="text" value={this.state.title} onChange={this.onChangeProductTitle} />
        </Form.Group>
        
        Imagen<br/><input type="text" id="foto" value={this.state.img} style={{color:"#fff",border:"0px"}} />
        <div id="upl" style={{float: "left", width:"100%", height:"120px"}}>                            
               <UploadContainer />                
          </div>
          
   
        <Form.Group controlId="Desc">
            <label htmlFor="exampleFormControlTextarea1">Descripción</label>
            <textarea className="form-control" value={this.state.desc} onChange={this.onChangeProductDesc} rows="5"/>          
        </Form.Group>
        

        <Button variant="danger" size="lg" block="block" type="submit">
          Crear producto
        </Button>
      </Form><br/><br/><br/><br/><br/>
    </div>);
  }
}