import React from "react";
import {  useEffect, useState, useRef, useLayoutEffect } from 'react'
import axios from 'axios';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';




function Login() {

  
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') { send() }
  }
  
  
  function send() {
    var p = document.getElementById('pass').value; 
    axios.get(process.env.REACT_APP_HOST + '/products/login/' + p).then(function (response) {
      if (response.data === 'yyy') {
        var dt = new Date();
        dt.setTime(dt.getTime() + 1000*36000);
        document.cookie = "mysti=mystic;expires=" + dt.toGMTString();
        document.location.href = '/admin';
      } else {
        document.getElementById('pass').value = '';
        document.getElementById('pass').focus();
        p = '';
      }
    }).catch(error => console.log(error))
  }


  
    
return (
<div style={{width:"100%",textAlign:"center",marginTop:"100px"}}>
<input id="pass" type="password" autoFocus onKeyDown={handleKeyDown} />          
</div>
);


}

export default Login;