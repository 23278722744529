import React from "react";

const Footer = () => {
return (
	
	<div class="footer" style={{float:"left",display:"inline-block",width:"100%",padding:"20px",height:"auto",paddingTop:"20px",paddingBottom:"50px",backgroundColor:"green",marginTop:"80px",color:"#fff",extAlign:"center"}}>

	  <div style={{float:"left",width:"100%",textAlign:"left",fontSize:"20px",marginLeft:"8px",}}>MEDIOS DE PAGO </div>

      <img src="/imgs/medios_de_pago.png" width="660" style={{float:"left",marginLeft:"0px",maxWidth:"100%"}} />

		<div style={{float:"left",width:"100%",textAlign:"left",fontSize:"20px",marginLeft:"9px",marginTop:"20px",marginBottom:"5px"}}>MEDIOS DE ENVIO </div>

      <img src="/imgs/medios_de_envio.png" width="150" style={{float:"left",marginLeft:"10px",maxWidth:"100%"}} />

	  <div style={{float:"left",width:"100%",textAlign:"left",fontSize:"20px",marginLeft:"10px",marginTop:"30px"}}>CONTACTANOS </div>

	  <div style={{float:"left",width:"100%",fontSize:"20px",marginBottom:"10px"}}><a href="tel:+543425480906"><img src="/imgs/telephone.png" width="30" style={{float:"left",marginLeft:"10px",marginTop:"10px"}} /><div style={{marginLeft:"60px",marginTop:"10px"}}>0342 5480906</div></a></div>

      <div style={{float:"left",width:"100%",fontSize:"20px",marginTop:"10px",marginBottom:"10px"}}><a href="mailto:mysti_colasso@hotmail.com"><img src="/imgs/email.png" width="30" style={{float:"left",marginLeft:"10px",marginTop:"10px"}} /><div style={{marginLeft:"60px",marginTop:"2px"}}>mysti_colasso@hotmail.com</div></a></div>

  <div style={{float:"left",width:"100%",fontSize:"20px",marginTop:"10px"}}><a href="instagram://user?username=natural.mystii"><img src="/imgs/instagram.png" width="30" style={{float:"left",marginLeft:"10px",marginTop:"10px"}} /><div style={{marginLeft:"60px",marginTop:"10px"}}>@natural.mystii</div></a></div>

<br/>
	</div>
	
);
};
export default Footer;
