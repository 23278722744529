import React from 'react'
import styled from 'styled-components'
import { Upload, message, Button } from 'antd';
import axios from 'axios';
const { Dragger } = Upload;

const Container = styled.div`
    width: 100%;
    height: 100%;
`

const Uploader = () => {

    const props = {
        name: 'photo',
        multiple: false,
        action: process.env.REACT_APP_HOST+'/photo',
        onChange (info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
  
            if (status === 'done') {
                //message.success(`${info.file.name} file uploaded successfully.`);
setTimeout(function(){ 
axios.get(process.env.REACT_APP_HOST+'/products/copy-img/'+info.file.name)
 }, 1000);

setTimeout(function(){ 
document.getElementById('upl').innerHTML = `<img src="/uploads/${info.file.name}" width="100" height="100" />`;
document.getElementById('foto').value = `${info.file.name}`;
 }, 3000);


//alert(`${info.file.name}`);

            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    return (
        <Container>
            <Dragger {...props}>
                <Button style={{backgroundColor:"#04AA6D",border:"1px solid #ccc",fontSize:"12px",lineHeight:"15px",marginTop:"10px",borderRadius:"10px",color:"#fff",padding:"10px"}}>
                    Seleccionar Imagen
                </Button>
            </Dragger>
        </Container>
    )
}

export default Uploader;