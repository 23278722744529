import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import UploadContainer from './containers/UploadContainer';
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link, useParams, useLocation } from "react-router-dom";
import ProductList from "./components/product-list.component";
import ProductListCat from "./components/product-list-cat.component";
import ProductView from "./components/product-view.component";
import {  useEffect, useState, useRef } from 'react';
import Footer from "./components/Footer";



/*
app.get('./*', (req, res) => {
   res.sendFile(path.join(__dirname+'/mysti/build/index.html'));
});
*/

function App() {

 

const loc = useLocation();
var l = loc.pathname.replace("/","_").replace("/admin/","_");

if(l.includes('Aromas') || l.includes('Belleza') || l.includes('Salud') || l === '_'){
localStorage.setItem("cat", l);
}

useEffect(() => { 
const cat = localStorage.getItem("cat");
if(cat != '_'){
document.getElementById(cat).style.borderBottom="2px solid #04AA6D";
}
}, []);


  return (



    <div className="App">

      <header className="App-header" style={{backgroundColor:"#EDFEF8"}}>

<Navbar bg="#E0FEF3" variant="dark" style={{width:"100%",borderBottom:"1px solid #eee",padding:"0px", margin:"0px 0px 0px 0px",
boxShadow: "0 6px 8px -2px #eee", mozBoxShadow: "0 6px 8px -2px #eee", webkitBoxShadow: "0 6px 8px -2px #eee", position:"fixed", zIndex:"999",backgroundColor:"#EDFEF8"}}>

          <Container>
            
              <a href="/">
                <img src="/imgs/NaturalMystii-330-94.png" width="140" style={{float:"left",marginLeft:"5px"}} />
              </a>
            

<a id="_Aromas" href="/Aromas" className="nav-links">Aromas</a>
<a id="_Belleza" href="/Belleza" className="nav-links">Belleza</a>
<a id="_Salud" href="/Salud" className="nav-links">Salud</a> 

          </Container>


        </Navbar>        
      </header>


      <Container>
        <Row>
          <Col md={12}>
            <div className="wrapper">
              <Switch>
                <Route exact path='/' component={ProductList} />
                <Route path="/product-list" component={ProductList} /> 
                <Route exact path="/:cat" component={ProductListCat} />
                <Route exact path="/product-view/:id" component={ProductView} />
              </Switch>
            </div>
          </Col>
        </Row>
      </Container>

<Footer />
    </div>

  );
}






export default App;
