import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Admin from "./admin/index.js";
import Login from "./admin/login.js";

ReactDOM.render(

  <BrowserRouter>    
<Switch>
    <Route path='/admin'><Admin /></Route>
   <Route exact path='/login' component={Login}><Login /></Route>
    <Route path='/'><App /></Route>
</Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();