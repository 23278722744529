import React, { Component } from "react";
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import ProductTableRow from './ProductTableRow';


export default class ProductList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      products: []
    };
  }

  componentDidMount() {
    axios.get('https://naturalmystii.com:2096/products/')
      .then(res => {
        this.setState({
          products: res.data
        });
      })
      .catch((error) => {
        console.log(error);
      })
  }

  DataTable() {
    return this.state.products.map((res, i) => {
      return <ProductTableRow obj={res} key={i} class="product-table-row" />;
    });
  }


  render() { 
    return (<div className="table-wrapper">
       <br/>
          {this.DataTable()}
      </div>);
  }
}