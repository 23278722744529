import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.css";
import "../App.css";
import { BrowserRouter as Router, Switch, Route, Link, useParams, useLocation } from "react-router-dom";
import CreateProduct from "../components/create-product.component";
import EditProduct from "../components/edit-product.component";
import ProductList from "../components/product-list.component";
import AdminProductList from "../components/AdminProductList.component";
import AdminProductListCat from "../components/AdminProductListCat.component";
import {  useEffect, useState, useRef, useLayoutEffect } from 'react'
import axios from 'axios';


function Index () {
var ret = 'no';
  if (document.cookie.indexOf('mysti') == -1) { document.location.href = '/login'; }else{ ret='yes'; }

  const loc = useLocation();
  var l = loc.pathname.replace("/admin", "_").replace("/", "");
  if (l.includes('Aromas') || l.includes('Belleza') || l.includes('Salud') || l === '_') {
    localStorage.setItem("cat", l);
  }
  useEffect(() => {
    const cat = localStorage.getItem("cat");
    if (cat != '_') {
      document.getElementById(cat).style.borderBottom = "2px solid #04AA6D";
    }
  }, []);


if(ret === 'yes'){
  return (


<Router>

<div className="App">

<header className="App-header" style={{backgroundColor:"#EDFEF8"}}>

<Navbar bg="#E0FEF3" variant="dark" style={{width:"100%",borderBottom:"1px solid #eee",padding:"0px", margin:"0px 0px 0px 0px",
boxShadow: "0 6px 8px -2px #eee", mozBoxShadow: "0 6px 8px -2px #eee", webkitBoxShadow: "0 6px 8px -2px #eee", position:"fixed", zIndex:"999",backgroundColor:"#EDFEF8"}}>
 
         <Container>
            
              <a href="/admin">
                <img src="/imgs/NaturalMystii-330-94.png" width="140" style={{float:"left",marginLeft:"5px"}} />
              </a>

  <a id="_Aromas" href={"/admin/Aromas"} className="nav-links">Aromas</a>
  <a id="_Belleza" href={"/admin/Belleza"} className="nav-links">Belleza</a>
  <a id="_Salud" href={"/admin/Salud"} className="nav-links">Salud</a>


            </Container>
            
  <a href="/admin/create-product" className="nav-links" style={{backgroundColor:"#04AA6D", borderBottomLeftRadius
:"10px", fontSize:"12px", color:"#fff", position:"absolute", margin:"0px", padding:"6px", top:"-4px", right:"0px"}}>
                  Nuevo
                </a>

              </Navbar>        
      </header>    
<br/><br/><br/>

      <Container>
        <Row>
          <Col md={12}>
            <div className="wrapper">
              <Switch>                
                <Route exact path='/admin' component={AdminProductList} />
                <Route path="/admin/create-product" component={CreateProduct} />
                <Route path="/admin/edit-product/:id" component={EditProduct} />
                <Route path="/admin/product-list" component={ProductList} />                
                <Route path="/admin/:cat" component={AdminProductListCat} />                
              </Switch>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </Router>

);
}
}

export default Index;