import React from 'react'
import styled from 'styled-components'
import Uploader from './../components/Uploader'

const Container = styled.div`
    width: 80px;
    height:120px;
    float:left;
    padding:0px 0px 20px;    
    display: block;
    align-items: left;
    justify-content: left;    
`

const UploadContainer = () => {
    return (
        <Container>
            <Uploader />
        </Container>
    )
}

export default UploadContainer;