import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Button from 'react-bootstrap/Button';

export default class ProductTableRow extends Component {


     render() { 

const im = '/uploads/'+this.props.obj.img;  
        return (

<div style={{float:"left",width:"100%",maxWidth:"300px",height:"300px",overflow:"hidden",marginBottom:"50px",marginLeft:"20px",marginRight:"20px"}}>    
<a href={"/product-view/" + this.props.obj._id}>
<div style={{float: "left", width:"100%", height:"30px", textAlign:"center",color:"#037248"}}>{this.props.obj.title}</div>
<div style={{float: "left", width:"100%", height:"auto"}}><img src={im} style={{float: "left", width:"100%", height:"auto"}} /></div>
</a>  

</div>


        );
    }
}